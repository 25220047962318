<template>
  <div ref="root">
    <!-- MAINTENANCE MODE -->
    <div id="maintenance" v-if="maintenanceMode == 1"
      class="update py-4 fixed top-0 left-0 bg-primary-900 w-full h-full text-white bg-black flex flex-col gap-4 p-8 items-center justify-center">
      <div class="w-full md:w-1/2 text text-center space-y-4">
        <svg class="mx-auto" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
          <!-- Circle -->
          <circle cx="50" cy="50" r="40" stroke="white" stroke-width="5" fill="transparent" stroke-dasharray="0, 251.2"
            id="circleDisconnected" />
          <!-- Tools icon -->
          <svg x="20" y="20" width="60" height="60" fill="#ffffff" viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.33 3.274a3.5 3.5 0 0 1 4.254 4.963l10.709 10.71-1.414 1.414L8.169 9.65a3.502 3.502 0 0 1-4.962-4.255l2.237 2.237a1.5 1.5 0 1 0 2.121-2.121l-2.236-2.24.001.002Zm10.367 1.884 3.182-1.768 1.414 1.414-1.768 3.182-1.768.354-2.12 2.12-1.415-1.413 2.121-2.121.354-1.768ZM8.979 13.29l1.414 1.414-5.303 5.303a1 1 0 0 1-1.492-1.327l.078-.087 5.303-5.303Z">
            </path>
          </svg>
          <!-- Animation -->
          <animate xlink:href="#circleDisconnected" attributeName="stroke-dasharray" dur="1s" from="0,251.2"
            to="251.2,251.2" begin="0s" fill="freeze" />
          <animate xlink:href="#maintenance" attributeName="opacity" dur="0.5s" from="0" to="1" begin="0.5s"
            fill="freeze" />
        </svg>
        <p class="text-2xl">Web en mantenimiento</p>
        <p>Estamos realizando tareas de mantenimiento en este momento.<br class="hidden md:block"> Por favor, vuelva a intentar ingresar más tarde.
        </p>
      </div>
    </div>

    <!-- MAIN -->
    <div class="mainPage" v-else-if="!loading && maintenanceMode == 0">
      <!-- NAVBAR -->
      <NavBar class="fixed w-full top-0 left-0 z-10 bg-black" v-if="!$route.path.includes('/seleccionar-complejo')">
        <!-- logo -->
        <router-link to="/" class="h-full flex-none flex gap-4 items-center">
          <div class="logo h-full aspect-video flex gap-4 items-center" v-if="$store.state.cinemaInfo.logo">
            <img :src="$store.state.cinemaInfo.logo" alt="Logo" class="h-full w-fit object-contain object-left">
            <div class="divider w-0 h-8 bg-white border-r" v-if="$store.state.cinemas.length > 1"></div>
            <p class="text-white font-bold uppercase text-sm flex-none" v-if="$store.state.cinemas.length > 1">
              {{ $store.state.cinemaInfo.nombre }}
            </p>
          </div>

          <p v-else class="text-white font-bold uppercase text-sm flex-none">
            {{ $store.state.cinemaInfo.nombre }}
          </p>
        </router-link>

        <!-- social -->
        <div class="social flex gap-4" v-if="!$store.state.kioskMode">
          <!-- Facebook -->
          <a v-if="$store.state.cinemaInfo.facebook" :href="$store.state.cinemaInfo.facebook">
            <img class="w-5 h-5" src="/assets/icons/facebook-brands.svg" alt="facebook" />
          </a>
          <!-- Twitter -->
          <a v-if="$store.state.cinemaInfo.twitter" :href="$store.state.cinemaInfo.twitter">
            <img class="w-5 h-5" src="/assets/icons/twitter-brands.svg" alt="twitter" />
          </a>
          <!-- Instagram -->
          <a v-if="$store.state.cinemaInfo.instagram" :href="$store.state.cinemaInfo.instagram">
            <img class="w-5 h-5" src="/assets/icons/instagram-brands.svg" alt="instagram" />
          </a>
        </div>
      </NavBar>

      <!-- MAIN -->
      <main class="text-white min-h-[100dvh]" :class="!$route.path.includes('/seleccionar-complejo') ? 'pt-16' : ''">
        <router-view v-slot="{ Component }">
          <transition name="fade">
            <!--keep-alive exclude="Pagar"-->
            <component :is="Component" />
            <!--/keep-alive-->
          </transition>
        </router-view>
      </main>

      <!-- FOOTER WEB -->
      <section class="footer bg-neutral-900" :class="$route.name != 'Inicio' ? 'md:block hidden' : ''" v-if="!$store.state.kioskMode"
        v-show="$route.path != '/seleccionar-complejo'">
        <div class="footerContainer p-10 flex flex-col md:flex-row gap-8 items-center justify-between">
          <div class="
              cinema-info
              flex flex-col
              gap-4
              items-center
              md:flex-row
            ">
            <!-- logo -->
            <div class="logo-container md:pr-4 md:border-r">
              <img v-if="$store.state.cinemaInfo.logo" :src="$store.state.cinemaInfo.logo" alt="Logo" class="h-8" />
              <p v-else class="text-white text-xl font-bold">
                {{ $store.state.cinemaInfo.nombre }}
              </p>
            </div>
            <!-- location -->
            <div class="location text-center md:text-left">
              <p class="text-white">{{ $store.state.cinemaInfo.direccion }}</p>
              <p class="text-white">
                {{ $store.state.cinemaInfo.localidad }},
                {{ $store.state.cinemaInfo.provincia }}
              </p>
            </div>
          </div>

          <div class="developer flex gap-8 text-center text-white">
            <div class="flex flex-col gap-2 items-center">
              <span class="text-xs">Desarrollado por</span>
              <a href="https://adro.studio" class="text-xl opacity-25">adro.studio</a>
            </div>
            <div class="flex flex-col gap-2 items-center">
              <span class="text-xs">Powered by</span>
              <img class="h-8" src="/assets/img/logo_gaf.svg" alt="">
            </div>
          </div>
        </div>

        <div class="version text-center text-white p-2 bg-black">
          <p class=" text-xs">v{{ $store.state.webAppVersion }}</p>
        </div>
      </section>
    </div>

    <!-- LOADING -->
    <div class="loading h-screen flex items-center justify-center" v-else>
      <Spinner />
    </div>

    <!-- PWA UPDATES-->
    <div
      class="update py-4 fixed top-0 left-0 bg-primary-900 w-full h-full text-white bg-black flex flex-col gap-4 p-8 items-center justify-center z-50"
      v-if="updateExists">
      <div class="text text-center space-y-8">
        <Spinner></Spinner>
        <p>Actualizando...</p>
        <p class="font-bold">Aguarde un momento, por favor</p>
      </div>
    </div>

    <!-- TOAST FOR ELECTRON UPDATES -->
    <Toast ref="toast" />
    
  </div>
</template>

<script lang="ts">
//update
import update from './update'

// utilities
import utilities from "./utilities"

// custom components
import Spinner from "@/components/Spinner.vue"
import NavBar from "@/components/NavBar.vue"
import Button from "@/components/Button.vue"
import Toast from "@/components/Toast.vue"
import { CustomVue } from "vuex";

//set date
const date = new Date();

//this component
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppView",
  components: {
    Spinner,
    NavBar,
    Button,
    Toast,
  },

  mixins: [update],

  data() {
    return {
      loading: true,
      cinemas: [] as any[any],
      maintenanceMode: 0,
      dateTime: {
        hours: date.getHours() < 10 ? "0" + date.getHours() : date.getHours(),
        minutes:
          date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(),
      }
    };
  },

  async created() {
    console.log(this.$route)
    //get config
    let cinemaConfig = {} as any[any]

    //get config from axios
    cinemaConfig = await utilities.getConfig()

    //get cinema id
    if (this.$route.params.cinemaId) {
      this.$store.state.cinemas = cinemaConfig.cinemas
      //get cinema id from url
      const cinemaId = this.$route.params.cinemaId as string
      this.getCinemaInfo(Number(cinemaId))
    }
    else {
      //Set cinema config and redirect on multicinema
      this.setCinemaConfig(cinemaConfig)
    }
  },

  methods: {
    async getCinemaInfo(cinemaId: number) {
      //get cinema info
      const cinemaInfo = await utilities.getFromApi("/cinemas/" + cinemaId);

      if (cinemaInfo.data.status == "ok") {
        //redirect to maintenance view
        if (cinemaInfo.data.data.mantenimiento == 1) {
          this.maintenanceMode = 1
        }
        else{
          this.$store.commit("setCinemaInfo", cinemaInfo.data.data);
          this.loading = false
        }
      } else {
        const toast = this.$refs.toast as any
        toast.show("Hubo un problema al cargar la información de este cine. Por favor, recargue la web.")
      }
    },

    setCinemaConfig(cinemaConfig: any) {
      //set header
      utilities.setHeader(cinemaConfig.title)

      //on multi cinema
      if (cinemaConfig.cinemas.length > 1) {
        this.$store.state.cinemas = cinemaConfig.cinemas
        this.$router.push('/seleccionar-complejo')
        this.$router.afterEach(() => {
          this.loading = false
        })
      }
      else {
        //set cinema info
        this.$store.commit("setCinemaInfo", cinemaConfig.cinemas[0])
        this.getCinemaInfo(this.$store.state.cinemaInfo.id)
      }
    },

    setDateTime() {
      const date = new Date();
      this.dateTime = {
        hours: date.getHours() < 10 ? "0" + date.getHours() : date.getHours(),
        minutes:
          date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(),
      };
    },

  }
});
</script>

<style>
* {
  font-family: 'Inter', sans-serif;
}

/*-- Transition --*/
.fade-enter-active,
.fade-leave-active {
  width: 100%;
}

.fade-enter-active {
  animation: enter .3s linear;
}

.fade-leave-active {
  opacity: 0;
}

@keyframes enter {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 100;
  }
}

@keyframes leave {
  0% {
    opacity: 100;
  }

  100% {
    opacity: 0;
  }
}
</style>
